import React from "react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/ogimage.jpg";

class SEO extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>SEA Easy Capital</title>
          <meta name="title" content="SEA Easy Capital" />
          <meta name="description" content="Turn Invoices into Cash" />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://sea-easy-capital.surge.sh/" />
          <meta property="og:title" content="SEA Easy Capital" />
          <meta property="og:description" content="Turn Invoices into Cash" />
          <meta
            property="og:image"
            content="http://sea-easy-capital.surge.sh/ogimage.jpg"
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content="http://sea-easy-capital.surge.sh/"
          />
          <meta property="twitter:title" content="SEA Easy Capital" />
          <meta
            property="twitter:description"
            content="Turn Invoices into Cash"
          />
          <meta
            property="twitter:image"
            content="http://sea-easy-capital.surge.sh/ogimage.jpg"
          />
        </Helmet>
      </div>
    );
  }
}

export default SEO;
