import React from "react";
import { Link } from "gatsby";
import logo from "../../assets/images/logo.svg";

const Footer = () => {
  // const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-4">
            <div className="single-footer-widget pl-5">
              <ul className="footer-links-list">
                <li>
                  <Link to="/" title="Home Page">
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/financing-solutions/supply-chain-financing"
                    title="Supply-Chain Financing"
                  >
                    SC Financing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/financing-solutions/invoice-financing"
                    title="Invoice Financing"
                  >
                    Invoice Financing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/financing-solutions/discounting"
                    title="Discounting"
                  >
                    Discounting
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-4">
            <div className="single-footer-widget">
              <ul className="footer-links-list">
                <li>
                  <Link
                    to="/about-us/about-sea-easy-capital"
                    title="About SEA Easy Capital"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Help & Support">
                    Help &amp; Support
                  </Link>
                </li>
                {/* <li>
                  <Link to="/" title="Blog Articles">
                    Blog
                  </Link>
                </li> */}
                <li>
                  <Link to="/" title="News & Events">
                    News &amp; Events
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-4">
            <div className="single-footer-widget">
              <ul className="footer-links-list">
                <li>
                  <Link to="/contact" title="Contact Us">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-use" title="Terms of Service">
                    Terms Of Use
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" title="Privacy Policy">
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <div className="single-footer-widget text-md-right text-sm-left text-center">
              <ul className="social-link">
                <li>
                  <Link
                    to="#"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/seaeasycapital/"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </Link>
                </li>
              </ul>
              <span>© SEA Easy Capital PTE. LTD.</span>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div> */}
    </footer>
  );
};

export default Footer;
