import React from "react";
import { useRecoilState } from "recoil";
import { collapsedState } from "../../utils/recoil-atoms";
import { Link } from "gatsby";
import logo from "../../assets/images/logo.svg";
import phone from "../../assets/images/phone-color.svg";
import whatsapp from "../../assets/images/whatsapp-color.svg";
import mail from "../../assets/images/mail-color.svg";

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  
  const loginUrl = process.env.LOGIN_URL;

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  });

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="contact-navbar">
          <div className="container-fluid">
            <a href="tel:+65 985 047781">
              <img src={phone} alt="icon" />
              <span>+65 985 047781</span>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=65985047781"
              target="_blank"
              rel="noreferrer"
            >
              <img src={whatsapp} alt="icon" />
              <span>+65 985 047781</span>
            </a>
            <a href="mailto:info@seaeasycapital.com">
              <img src={mail} alt="icon" />
              <span>info@seaeasycapital.com</span>
            </a>
          </div>
        </div>
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <img src={logo} alt="logo" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" activeClassName="active" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#"
                      activeClassName="active"
                      onClick={(e) => e.preventDefault()}
                      className="nav-link"
                    >
                      Financing Solutions <i className="bx bx-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/financing-solutions/supply-chain-financing"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Suppy Chain Financing
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/financing-solutions/invoice-financing"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Invoice Financing
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/financing-solutions/discounting"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Discounting
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/financing-solutions/trade-finance"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Trade Finance
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="#"
                      activeClassName="active"
                      onClick={(e) => e.preventDefault()}
                      className="nav-link"
                    >
                      Resources <i className="bx bx-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      {/* <li className="nav-item">
                        <Link
                          to="/resources/blog"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Blog
                        </Link>
                      </li> */}

                      {/* <li className="nav-item">
                        <Link
                          to="/resources/news"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          News &amp; Events
                        </Link>
                      </li> */}
                      {/* <li className="nav-item">
                        <Link
                          to="/resources/"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Help Center
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link
                          to="/resources/faq"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          FAQ
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a
                          href="http://userdoc.seaeasycapital.com/"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          User Guide
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="#"
                      activeClassName="active"
                      onClick={(e) => e.preventDefault()}
                      className="nav-link"
                    >
                      About Us <i className="bx bx-chevron-down"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/about-us/about-sea-easy-capital"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          About SEA Easy Capital
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/about-us/our-team"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Our Team
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link
                          to="/about-us/careers"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Careers
                        </Link>
                      </li> */}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/contact"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>

                <div className="others-option d-flex align-items-center">
                  <div className="option-item">
                    <Link
                      to={loginUrl}
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="default-btn default-btn--light no-icon"
                      target="_blank"
                    >
                      {" "}
                      Login<span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
